
<style scoped lang="less">
.t_exam_online_checked {
  box-shadow: @shadow;
  background: #fff;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }
  .content {
    padding: 0 20px 20px;
  }
}
</style>
<template>
  <div class="t_exam_online_checked">
    <div class="stu-module-header">
      <div class="stu-module-title">批阅管理</div>
    </div>
    <el-tabs v-model="form.status"  @tab-click="teaCourseStudentExamList">
      <el-tab-pane label="未批阅列表" name="1"></el-tab-pane>
      <el-tab-pane label="已批阅列表" name="2"></el-tab-pane>
      <el-tab-pane label="已毕业列表" name="3"></el-tab-pane>
    </el-tabs>
    <div class="content">
      <el-form
        :model="form"
        size="medium"
        label-width="80px"
        inline
        ref="exam_checked_ref"
      >
        <el-form-item label="姓名/学号" prop="name">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="函授站" prop="siteName">
          <el-select v-model="form.siteName" clearable>
            <el-option
              v-for="item in siteNameOptions"
              :key="item.id"
              :label="item.siteName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年级">
          <el-select v-model="form.gradeId" clearable @change="init">
            <el-option
                v-for="(item, index) in gradeOptions"
                :key="index"
                :label="item.gradeName"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="层次">
          <el-select v-model="form.stageId" clearable @change="init">
            <el-option
                v-for="(item, index) in stageOptions"
                :key="index"
                :label="item.stageName"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业">
          <el-select v-model="form.profId" clearable @change="init">
            <el-option
                v-for="(item, index) in professionalOptions"
                :key="index"
                :label="item.profName"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提交时间" prop="date">
          <el-date-picker
            v-model="form.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="teaCourseStudentExamList" type="primary">查询</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" :height="height" stripe>
        <el-table-column label="序号" width="65" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(col, i) in column"
          :key="i"
          :label="col.label"
          :prop="col.prop"
          :min-width="col.width"
          :sortable="col.sortable"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="操作"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="form.status === '1'"
              @click="checked(scope.row)"
            >
              批阅
            </el-button>
            <el-button type="text" @click="open(scope.row)"> 查看 </el-button>
            <el-button type="text" v-if="form.status === '2'" @click="reject(scope.row)"> 驳回 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="tableData.length"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {EduGrade, EduProfession, EduStage, QueryAllSite} from "../../../../../libs/api/teacher/select";

export default {
  name: "t_exam_online_checked",
  mounted() {
    this.selectInit();
  },
  data() {
    return {
      height: window.innerHeight - 500,
      form: {
        name: "",
        siteId: "",
        gradeId: "",
        profId: "", // 专业
        stageId: "",
        date: [], // 提交时间
        status:"1",
      },
      siteNameOptions: [], // 函授站码表
      gradeOptions: [], // 年级码表
      professionalOptions: [], // 专业码表
      stageOptions: [], // 层次码表
      teacherOptions: [], // 批阅人码表
      tableData: [],
      column: [
        { label: "姓名", prop: "name" },
        { label: "学号", prop: "studentNo" },
        { label: "函授站", prop: "siteName" },
        { label: "年级", prop: "gradeName" },
        { label: "专业", prop: "profName" },
        { label: "层次", prop: "stageName" },
        { label: "得分", prop: "score", sortable: true },
        { label: "提交时间", prop: "commitTime" },
      ],
      total: 0,
      pageSize: 10,
      current: 1,
    };
  },
  created() {
    this.teaCourseStudentExamList()
  },
  activated() {
    this.teaCourseStudentExamList()
  },
  methods: {
    selectInit() {
      let data = new FormData();
      // data.append("siteId", this.user.siteId);
      EduGrade(data).then((res) => {
        this.gradeOptions = res.data;
      });
      EduStage(data).then((res) => {
        this.stageOptions = res.data;
      });
      EduProfession(data).then((res) => {
        this.professionalOptions = res.data;
      });
      QueryAllSite(data).then((res) => {
        this.siteNameOptions = res.data;
      });
      // EduStage, EduProfession;
    },
    query() {},
    init() {},
    teaCourseStudentExamList(){
      this.openLoadingView();
      let formdata=new FormData();
      formdata.append("info",this.form.name);
      if(this.form.date!=null&&this.form.date!=""){
        if (this.form.date == null){
          this.form.date = ['',''];
        }
        if (this.form.date[0] instanceof  Date){
          this.form.date[0] = new Date(this.form.date[0]).formatToLocalDateTime("yyyy/MM/dd");
        }
        if (this.form.date[1] instanceof Date){
          this.form.date[1] = new Date(this.form.date[1]).formatToLocalDateTime("yyyy/MM/dd");
        }
        formdata.append("startTime",this.form.date[0]);
        formdata.append("endTime",this.form.date[1]);
      }
      formdata.append("gradeId",this.form.gradeId);
      formdata.append("profId",this.form.profId);
      formdata.append("stageId",this.form.stageId);
      formdata.append("siteId",this.form.siteId);
      formdata.append("status",this.form.status);
      this.$axios_supermallData.post("/hnjxjy-core/TeaCourseStudentExam/list",formdata).then(res => {
        if (res.data.code == 2000) {
          this.tableData = res.data.data.list;
            this.loadingView.close()
        }
      })
    },
    // 批阅
    checked(row) {
      if (row) {
        const { id, student_id } = row;
        const { workSetId, courseId } = this.$route.query;
        this.$router.push({
          path: "checked/view",
          query: { id, student_id, workSetId, courseId },
        });
      }
    },

    // 查看
    open(row) {
      if (row) {
        this.$router.push({
          path:"previews",
          query:{
            id:row.id,
            studentId:row.student_id}
        });
      }
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },
    reset() {
      this.$refs["exam_checked_ref"].resetFields();
    },
    // 驳回
    reject(row) {
      this.$axios_supermall.post("/hnjxjy-core/TeaCourseStudentExam/delete?ids="+row.id).then(resp => {
        if (resp.data.code == 2000) {
          this.$message.success("操作成功")
          this.teaCourseStudentExamList()
        }else{
          this.$message.error("操作失败")
        }
      })
    },
  },
};
</script>
